import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './App.css';


class Navigation extends Component {
  
  render() {
    return (
    <nav className="menu">
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/contact">Contact</Link>
    </nav>
    );
  }
}

export default Navigation;